import React from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { useCart as UseCart } from '@jetshop/core/components/Query/CartProvider';
import { useIntl } from '@jetshop/intl';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import cartQuery from '../Cart/CartQuery.gql';
import { CenteredSpinner } from '../ui/Spinner';
import AddAllToCart from './AddAllToCart';
import FavouritesFlyoutItem from './FavouritesFlyoutItem';
import ProductsWithVariantsQuery from './ProductsWithVariantsQuery.gql';
import useFavourites from './useFavourites';

const StyledCloseIconBold = styled(Cross)`
  width: 2rem;
  height: 3rem;

  &:hover {
    cursor: pointer;
  }
`;

const LightText = styled('span')``;

const Header = styled('header')`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.greyLight};
  h2 {
    font-size: 1.25rem;
    font-weight: ${theme.fontWeights.medium};
    text-align: center;
  }
  ${LightText} {
    display: block;
  }
  padding: 0 2rem;
  border-bottom: 1px solid ${theme.colors.grey};
  height: 62px;
  z-index: 10;
`;

const FavouritesFlyoutWrapper = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1005;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const CloseButton = styled('button')`
  outline: none;
  background: none;
  position: absolute;
  top: 22px;
  right: 30px;
  height: 14px;
  width: 14px;

  svg {
    stroke: ${theme.colors.primary};
    height: 14px;
    width: 14px;
  }
`;

const RecommendToFriendButton = styled(Link)`
  transition: 0.2s background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: ${theme.colors.green};
  color: ${theme.colors.white};
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background: ${theme.colors.greenHover};
  }
`;

const Content = styled('div')`
  position: relative;
  padding-top: 62px;
  height: 100%;
`;

const FavouritesWrapper = styled('div')`
  height: calc(100% - 104px);
  overflow: auto;

  ${theme.below.md} {
    height: ${props =>
      props.addToCart ? `calc(100% - 184px)` : `calc(100% - 104px)`};
  }
`;

const Cover = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.white};
  opacity: 0.6;
`;

const Summary = styled('section')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.25rem;
  background: ${theme.colors.greyLight};
  border-top: 1px solid ${theme.colors.grey};

  > * {
    flex: 1 1 calc(50% - 8px);
    margin: 0 8px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  ${theme.below.md} {
    display: block;
    > * {
      width: 100%;
      margin: 0;

      &:nth-child(2n + 2) {
        margin-top: ${theme.space[2]};
      }
    }
  }
`;

const FavouritesFlyoutView = ({ drawer, ...props }) => {
  const { favourites } = useFavourites();
  // const [selectionMode, setSelectionMode] = useState(false);
  useTracker();
  const [isLoading] = React.useState(false);
  const t = useIntl();

  const getUpdatedCart = () => {
    const {
      cart,
      result: { loading }
    } = UseCart(cartQuery);

    if (loading) return [];

    return cart?.items || [];
  };

  const [cartItems, setCartItems] = React.useState(getUpdatedCart());

  const onCompleted = ({ data }) => {
    getCartItems && getCartItems(data.addMultipleToCart.cart?.items);
    isLoading && isLoading(false);
  };

  const getCartItems = items => {
    setCartItems(items);
  };

  return (
    <FavouritesFlyoutWrapper {...props}>
      <Header>
        <h3>{t('Favourites')}</h3>

        <CloseButton onClick={drawer.hideTarget} aria-label={t('Close modal')}>
          <StyledCloseIconBold />
        </CloseButton>
      </Header>
      <Content className={favourites.length === 0 ? 'noFavourites' : ''}>
        <Query
          variables={{
            articleNumbers: favourites.map(f => f.articleNumber)
          }}
          query={ProductsWithVariantsQuery}
          errorPolicy="ignore"
        >
          {result => {
            const { data, loading } = result;

            if (!data || !data.products) {
              if (loading) return <CenteredSpinner />;
              return null;
            }

            const products = favourites
              .filter(favourite => !favourite.ignored)
              .map(favourite => {
                const product = data.products.find(
                  p =>
                    p.articleNumber === favourite.articleNumber ||
                    (p.variants &&
                      p.variants.values.find(
                        v => v.articleNumber === favourite.articleNumber
                      ))
                );

                if (!product) {
                  // If the product is hidden, or otherwise unavailable, toggle it
                  // toggleFavourite(favourite); // Not allowed in f.body - TODO check somewhere else
                  return null;
                }
                const selectedVariant =
                  product &&
                  product.variants &&
                  product.variants.values.find(
                    v => v.articleNumber === favourite.articleNumber
                  );

                return {
                  ...favourite,
                  product,
                  selectedVariant
                };
              });

            const items = products.filter(product => {
              const inCart =
                cartItems &&
                cartItems.find(
                  item => item?.articleNumber === product?.articleNumber
                );

              return inCart ? false : true;
            });

            return (
              <>
                <FavouritesWrapper addToCart={items.length}>
                  {data.products.map(product => {
                    const inCart = cartItems.find(
                      item => item.articleNumber === product.articleNumber
                    );

                    return (
                      <FavouritesFlyoutItem
                        product={product}
                        drawer={drawer}
                        key={product.id}
                        inCart={inCart}
                        setCartItems={setCartItems}
                        onCompleted={onCompleted}
                      />
                    );
                  })}

                  {isLoading && <Cover />}
                </FavouritesWrapper>

                <Summary>
                  {items.length ? (
                    <AddAllToCart
                      items={items}
                      getCartItems={getCartItems}
                      onCompleted={onCompleted}
                    />
                  ) : null}

                  <RecommendToFriendButton
                    to="/favoriter"
                    onClick={drawer.hideTarget}
                  >
                    {t('Share your list')}
                  </RecommendToFriendButton>
                </Summary>
              </>
            );
          }}
        </Query>
      </Content>
    </FavouritesFlyoutWrapper>
  );
};

export default FavouritesFlyoutView;
