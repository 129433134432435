import t from '@jetshop/intl';

// const numberWithSymbol = (x, seperator) => {
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator);
// };

//formatterSEK and formattedNumber uses intl.formatToParts which is currently usable in most browsers.
//Check https://www.chromestatus.com/feature/5686840812109824 for status.

// const formatterSEK = new Intl.NumberFormat('sv', {
//   style: 'currency',
//   currency: 'SEK',
//   minimumFractionDigits: 0,
//   maximumFractionDigits: 0
// });

// const formattedNumber = (value, seperator) => {
//   return formatterSEK
//     .formatToParts(value)
//     .map(({ type, value }) => {
//       switch (type) {
//         case 'group':
//           return seperator;
//         default:
//           return value;
//       }
//     })
//     .reduce((string, part) => string + part);
// };

const PriceFormatter = (value, currencyCode, seperator = '.') => {
  if (currencyCode === 'SEK') {
    //Using '\u00A0' to include a non line-breaking space.
    //return `${numberWithSymbol(value, seperator)}${'\u00A0'}kr`;
    return t.number(value, currencyCode);
    //return formattedNumber(value, seperator);
  } else {
    // Fallback to default behaviour
    return t.number(value, currencyCode);
  }
};

export default PriceFormatter;
