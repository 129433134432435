import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';

import { theme } from '../Theme';

export const AddToCartButton = styled('button')`
  transition: 0.2s background-color;
  background: none;
  outline: none;

  &:hover {
    background-color: ${theme.colors.primaryHover};
  }
`;

const AddAllToCart = ({
  children,
  title,
  items,
  getCartItems,
  isLoading,
  hasFailedItems,
  onCompleted
}) => {
  // const onCompleted = ({ data }) => {
  //   getCartItems && getCartItems(data.addMultipleToCart.cart.items);
  //   isComplete && isComplete();
  //   isLoading && isLoading(false);
  // };
  //
  const [addMultipleToCart] = useAddMultipleToCart(
    items,
    { cartQuery, addMultipleToCartMutation },
    { onCompleted }
  );

  // isLoading && isLoading(loading);
  // hasFailedItems && hasFailedItems(failedItems);

  return (
    <AddToCartButton
      onClick={() => {
        addMultipleToCart(items);
      }}
    >
      {title || t('Add to cart')}
      {children}
    </AddToCartButton>
  );
};

export default AddAllToCart;
